import React from 'react';
import {graphql} from 'gatsby';
import {Helmet} from 'react-helmet';
import Layout from '../components/Layout';
import styles from './styles/index.module.scss';

const IndexPage = ({data}) => {
    const image = data.contentfulPageContent.mainImage.fluid;

    return (
        <>
            <Helmet>
                <title>{data.site.siteMetadata.title}</title>
                <meta
                    name="description"
                    content={data.site.siteMetadata.description}
                />
                <meta
                    name="og:title"
                    content={data.site.siteMetadata.title}
                />
                <meta
                    name="og:description"
                    content={data.site.siteMetadata.description}
                />
                <meta
                    name="og:url"
                    content={data.site.siteMetadata.url}
                />
                <meta name="og:type" content="website" />
            </Helmet>
            <Layout>
                <picture>
                    <source srcSet={image.srcSet} sizes={image.sizes} />
                    <source srcSet={image.srcSetWebp} sizes={image.sizes} />
                    <img src={image.src} alt="" className={styles.mainImage} />
                </picture>
            </Layout>
        </>
    );
};

export default IndexPage;

export const query = graphql`
query {
  site {
    ...SiteMetadata
  }
  contentfulPageContent(title: {eq: "Homepage"}) {
    mainImage {
      fluid(maxWidth: 1920, quality: 90) {
        src
        srcWebp
        srcSet
        srcSetWebp
        aspectRatio
      }
    }
  }
}
`;
